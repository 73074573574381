import React from 'react'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
import Logo from '../assets/images/chirpyest-logo.svg'
import { FONTS } from '../constants'
import SEO from '../components/seo'

export const styles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    paddingTop: 130,
    fontFamily: FONTS.Schnyder.SchnyderMLight,
  },
  brandName: {
    fontWeight: 700,
    marginBottom: 150,
    fontSize: 56,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginBottom: 110,
    },
  },
  username: {
    fontSize: 30,
    fontWeight: 300,
    letterSpacing: 0,
    margin: 0,
    marginBottom: 10,
    lineHeight: '34px',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontFamily: 'Schnyder XL',
      fontSize: 20,
      lineHeight: '23px',
      marginBottom: 15,
    },
  },
  cashBack: {
    fontSize: 60,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '68px',
    margin: 0,
    marginBottom: 10,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: 45,
      lineHeight: '46px',
      marginBottom: 15,
    },
  },
  caption: {
    fontFamily: 'Graphik',
    fontSize: 16,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '26px',
    marginTop: 25,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '22px',
      marginTop: 50,
    },
  },
  logo: {
    position: 'absolute',
    top: '60px',
    right: 0,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      top: '20px',
    },
  },
  wrapper: {
    width: '80%',
    position: 'relative',
    margin: 'auto',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      width: '90%',
    },
  },
}))

const Cashback = () => {
  const { search } = useLocation()
  const { trackingLink, brandName, commission, userId, username } = parse(
    search
  )
  const loggedInUserId = window.localStorage.getItem('user_id')
  const isLoggedIn = Boolean(loggedInUserId)
  const isCashBackShown = commission && commission !== 'undefined'
  const classes = styles()

  if (!isLoggedIn || loggedInUserId !== userId || !isCashBackShown) {
    window.location.href = decodeURIComponent(`${trackingLink}`)
    return null
  }

  setTimeout(
    () => (window.location.href = decodeURIComponent(`${trackingLink}`)),
    2000
  )

  return (
    <>
      <SEO title={`Cash Back At ${brandName}`} />
      <div className={classes.wrapper}>
        <img
          className={classes.logo}
          src={Logo}
          alt="logo"
          width={49}
          height={52}
        />
        <div className={classes.container}>
          <div className={classes.brandName}>{brandName}</div>
          <div className={classes.username}>congratulations {username}</div>
          <div>
            <p className={classes.cashBack}>{commission} cash back!</p>
            <p className={classes.username}>at {brandName}</p>
          </div>
          <div className={classes.caption}>
            <p style={{ margin: 0 }}>
              nothing more for you to do. it’s that simple!
            </p>
            <p style={{ margin: 0 }}>
              cash back will be added to your chirpyest account within one week.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cashback
